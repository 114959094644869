import React, { useState } from 'react'
import './ImageSlider.css'
import { ImageData } from './ImageData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

const ImageSlider = ({ slides }) => {
    const [current, setCurrent] = useState(0)
    const length = slides.length

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    if(!Array.isArray(slides) || slides.length <= 0) {
        return null
    }

  return (
    <div className='slider__container'>
        <button className='image__btn left' onClick={prevSlide}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {ImageData.map((slide, index) => {
            return (
                <div className={index === current ? 'slide active' : 'slide'} key={index}>
                    {index === current && (
                        <div className='slider__image'>
                            <img src={slide.image} alt='' />
                        </div>
                    )}
                </div>
            )
        })}
        <button className='image__btn right' onClick={nextSlide}>
            <FontAwesomeIcon icon={faAngleRight} />
        </button>
    </div>
  )
}

export default ImageSlider