import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer__container'>
        <div className='footer__content'>
            <p>2022 &copy; Copyright <strong>ColdCityWraps</strong>. All Rights Reserved.</p>
        </div>
    </div>
  )
}

export default Footer