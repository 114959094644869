import React from 'react'
import './Intro.css'

const Intro = () => {
  return (
    <div className='intro__container'>
        <div className='intro__title'>
            <h2>let's begin transforming</h2>
        </div>
        <div className='intro__p'>
            <p>
              Here at Cold City Wraps we dont just change colors, we trasnform your vehicle. We offer a variety of 
              services from vehicle wraps, window tint, custom decals, custom banners, and PPF (paint protection film). 
              We offer a variety of styles of wraps for vehicles from vinyl, matte, satin, gloss, and custom. From our 
              option available, we garuntee you can change your with affordeable rates to ensure you love driving your 
              car daily.
            </p>
        </div>
        <a className='intro__btn' href='mailto: coldcitywraps@gmail.com'>request a quote</a>
    </div>
  )
}

export default Intro