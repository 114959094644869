import React from 'react'
import './FooterNav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const FooterNav = () => {
  return (
    <div className='footerNav__container'>
        <Link to='/' replace style={{ textDecoration: 'none', color: 'white' }}>
            <div className='footerNav__col1'>
                <h2>Cold City Wraps</h2>
            </div>
        </Link>
        <div className='footerNav__col2'>
            <p>Bayad Asad & Yaad Asad<br/>NW Calgary, Alberta<br/>Canada<br/><br/><strong>Phone:</strong> 403 401 7307<br/><strong>Email:</strong> coldcitywraps@gmail.com</p>
        </div>
        <div className='footerNav__col3'>
            <h2 className='footerNav__h2'>useful links</h2>
            <ul>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <a href='/' className='link'>Home</a>
                </li>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <a href='/about' className='link'>About</a>
                </li>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <a href='/services' className='link'>Services</a>
                </li>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <a href='/contact' className='link'>Contact</a>
                </li>
            </ul>
        </div>
        <div className='footerNav__col4'>
            <h2 className='footerNav__h2'>our expertise</h2>
            <ul>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <p>Vehicle Wraps</p>
                </li>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <p>Window Tint</p>
                </li>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <p>Custom Decals</p>
                </li>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <p>Custom Banners</p>
                </li>
                <li>
                    <span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <p>PPF (Paint Protection Film)</p>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default FooterNav