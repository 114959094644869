export const ServiceData = [
    {
        id: 0,
        title: "Vehicle Wraps",
        description: "Specialize in full vehicle wraps from a variety of colors and styles. Our styles include; vinyl, matte, satin, gloss, or a custom style of your choice. We strive for perfect installs and minimize wrinkles and seams."
    },
    {
        id: 1,
        title: "Window Tint",
        description: "Our professional installers have great experience with window tints and value customer satisfaction. We offer a variety of tints from 5% to 50% and garuntee affordeable prices. Contact us with vehicle make, model, and year to get an accurate quote."
    },
    {
        id: 2,
        title: "Paint Protection Film (PPF)",
        description: "Clear film that protects vehicle from stone chips and road debree which also enchances the gloss finish of your vehicle."
    },
    {
        id: 3,
        title: "Custom Banners",
        description: "We offer regular and drip banners with a variety of colors, best prices in YYC garunteed."
    },
    {
        id: 4,
        title: "Custom Decals",
        description: "custom stickers of your choice; logos, images, and brands."
    }
]