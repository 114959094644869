import React from 'react'
import ContactContent from './ContactContent'

const Contact = () => {
  return (
    <div className='section__container'>
        <div className='section__title'>
          <h2>contact</h2>
        </div>
        <div className='section__image'>
          <img
            src='https://images.unsplash.com/photo-1497215842964-222b430dc094?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
            alt=''
          />
        </div>
        <ContactContent />
    </div>
  )
}

export default Contact