import React from 'react'
import ServiceContent from './ServiceContent'
import { ServiceData } from '../Home/ServiceData'
import InfoFormat from '../InfoFormat'

const Services = () => {
  return (
    <div className='section__container'>
        <div className='section__title'>
          <h2>services</h2>
        </div>
        <div className='section__image'>
          <img
            src='https://cdn.pixabay.com/photo/2016/11/18/23/04/cleaning-1837331_960_720.jpg'
            alt=''
          />
        </div>
        <ServiceContent />
        <div className='service__format'>
            {ServiceData.map((data, id) => (
            <InfoFormat 
                key={id}
                data = {data}
            />
            ))}
        </div>
    </div>
  )
}

export default Services