import React, { useState } from 'react'
import './Nav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Nav = () => {
  const [toggleNav, setToggleNav] = useState(false)

return (
  <nav style={toggleNav ? {height: "100vh"} : {height: "90px"}}>
      <div className='nav__innerContainer'>    
          <div className='nav__left'>
              <Link to="/" style={{ textDecoration: 'none', color: 'white' }} onClick={() => (setToggleNav(false))}>
                <div className='nav__title'>
                    <h1>Cold City Wraps</h1>  
                </div>
              </Link>
          </div>
          <div className='nav__right'>
              <div className='nav__linkContainer'>
                  <div className='nav__item'>
                    <a href='/'>Home</a>
                  </div>
                  <div className='nav__item'>
                    <a href='/about'>About</a>
                  </div>
                  <div className='nav__item'>
                    <a href='/services'>Services</a>
                  </div>
                  <div className='nav__item'>
                    <a href='/contact'>Contact</a>
                  </div>
                  <button className='nav__btn' onClick={() => (setToggleNav(!toggleNav))}>
                      {toggleNav ? <> &#10005; </> : <><FontAwesomeIcon icon={faBars}/></>}
                  </button>
              </div>
          </div>
      </div>
      { toggleNav && (
          <div className='nav__extendedContainer'>
              <div className='link__extended'>
                <a href='/' onClick={() => (setToggleNav(false))}>Home</a>
              </div>
              <div className='link__extended'>
                <a href='/about' onClick={() => (setToggleNav(false))}>About</a>
              </div>
              <div className='link__extended'>
              <a href='/services' onClick={() => (setToggleNav(false))}>Services</a>
              </div>
              <div className='link__extended'>
              <a href='/contact' onClick={() => (setToggleNav(false))}>Contact</a>
              </div>
          </div>
      )}
  </nav>
)}

export default Nav