export const AboutData = [
    {
        title: "Cuztomized",
        description: "Our team of skilled professionals have a well known reputation in the market allowing us to get a wide range of material to ensure you get the look you want."
    },
    {
        title: "Satisfaction",
        description: "We value our customers feedback and love showing them the final result of their vehicle. Whatever you are imagining with your car, we'll deliver."
    },
    {
        title: "Safety",
        description: "Our custom designed services along side latest modern technology gives us the eagle eye vision to insure a precise services with no damage to your vehicle."
    },
    {
        title: "Trustworthy",
        description: "We dont do this because we have to, we do it because we love to. Our team of hardworking individuals have been trusted throughout Calgary and Toronto and continue to gain recognition."
    }
]