import React from 'react'
import AboutContent from './AboutContent'

const About = () => {
  return (
    <div className='section__container'>
        <div className='section__title'>
          <h2>about us</h2>
        </div>
        <div className='section__image'>
          <img
            src='https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80'
            alt=''
          />
        </div>
        <AboutContent />
    </div>
  )
}

export default About