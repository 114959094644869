import React from 'react';
import Particles from 'react-tsparticles';

const ParticleBackbround = () => {
    const particlesInit = (main) => {;
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
      };
    
      const particlesLoaded = (container) => {;
      };
      return (
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 50,
            fullScreen: {
                enable: true,
            },
            interactivity: {
              events: {
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                repulse: {
                  distance: 140,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#fff",
              },
              move: {
                direction: "bottom",
                enable: true,
                outMode: "out",
                random: false,
                speed: 4.5,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.4,
                random: true,
                anim: {
                    enable: true,
                    speed: 2.8,
                    opacity_min: 0.1,
                },
              },
              shape: {
                type: "image",
                image: {
                    src: "snowflake.png",
                },
              },
              size: {
                random: true,
                value: 7,
              },
            },
            detectRetina: false,

          }}
        />
      );
    };
export default ParticleBackbround