import React, {useState, useEffect} from 'react';
import './Home.css'
import Intro from './Intro';
import { ServiceData } from './ServiceData';
import { ImageData } from './ImageData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import ImageSlider from './ImageSlider';
import InfoFormat from '../InfoFormat';
import ParticleBackbround from '../particleBackbround';

const Home = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 40) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  return (
    <div className='home__container'>
      <ParticleBackbround /> 
      <ImageSlider
        slides = {ImageData}
      />
      <Intro />
      {showButton && (
        <button className='footer__btn' onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}
      <div className='service__format'>
        {ServiceData.map((data, id) => (
          <InfoFormat 
            key={id}
            data = {data}
          />
        ))}
      </div>
    </div>
  )
}

export default Home