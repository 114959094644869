import React from 'react'
import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
  return (
    <div className='header__container'>
        <div className='header__content'>
            <div className='header__contact'>
                <FontAwesomeIcon className='header__icon' icon={faEnvelope} />
                <a className='link' href='mailto: coldcitywraps@gmail.com'>coldcitywraps@gmail.com</a>
            </div>
            <div className='header__contact'>
                <FontAwesomeIcon className='header__icon' icon={faPhone} />
                <a className='link' href='Tel: 4034017307'>403 401 7307</a>
            </div>
        </div>
    </div>
  )
}

export default Header