import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Nav from './components/Nav';
import Header from './components/Header';
import FooterNav from './components/FooterNav';
import Footer from './components/Footer';
import Services from './components/Services/Services';

function App() {
  return (
    <Router>
      <Header />
      <Nav />
      <Routes>
        <Route path='/' element={<Home />}>
        </Route>
        <Route path='about' element={<About />}>
        </Route>
        <Route path='services' element={<Services />}>
        </Route>
        <Route path='contact' element={<Contact />}>
        </Route>
      </Routes>
      <FooterNav />
      <Footer />
    </Router>
  );
}

export default App;
