import React from 'react'
import './ServiceContent.css'

const ServiceContent = () => {
  return (
    <div className='serviceIntro__container'>
        <div className='serviceIntro__title'>
            <h2>upgrades</h2>
        </div>
        <div className='serviceIntro__p'>
            <p>
              When it comes to quality wraps, we're the ones for the job. Our wrapping professionals 
              have plenty of experience with a large number of vehicles and garuntee no seams or 
              wrinkles and insure perfect installations every time. Contact us via our socials to get 
              an accurate quote for the color wrap of your choice. Let's begin a transformation today.
            </p>
        </div>
    </div>
  )
}

export default ServiceContent