import car1 from '../../assets/c1.png';
import car2 from '../../assets/c2.png';
import car3 from '../../assets/c3.png';

export const ImageData = [
    {
        id: 0,
        image: car1
    },
    {
        id: 1,
        image: car2
    },
    {
        id: 2,
        image: car3
    }
]