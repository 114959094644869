import React from 'react'
import './InfoFormat.css'

const InfoFormat = ({ data }) => {
  return (
    <ul className='data__container'>
      <li className='data__info'>
        <h2>{data.title}</h2>
        <p>{data.description}</p>
      </li>
    </ul>
  )
}

export default InfoFormat