import React from 'react'
import './ContactContent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const ContactContent = () => {
  return (
    <div className='contactContent__container'>
        <ul>
            <li>
                <span>
                    <FontAwesomeIcon icon={faLocationDot} />
                </span>
                <div className='list__content'>
                    <h3>Location:</h3>
                    <p> NW Calgary, Alberta<br/>Canada</p>
                </div>
            </li>
            <li>
                <span>
                    <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <div className='list__content'>
                    <h3>Email:</h3>
                    <p> coldcitywraps@gmail.com</p>
                </div>
            </li>
            <li>
                <span>
                    <FontAwesomeIcon icon={faPhone} />
                </span>
                <div className='list__content'>
                    <h3>Call:</h3>
                    <p> Bayad Asad & Yaad Asad<br />403 401 7307</p>
                </div>
            </li>
        </ul>    
    </div>
  )
}

export default ContactContent